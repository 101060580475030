import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import Structure from 'components/Structure'
import { Jumbotron, Container } from 'reactstrap';

import MainHeader from "components/header"
import "components/layout.scss"

const headerNavigation = [
  {text: "Customer API", link: "/customer"},
  {text: "Partner API", link: "/partner"},
]

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
      <MainHeader nav={headerNavigation} />
      <Jumbotron fluid>
        <Container fluid>
          <center><h1 className="jumbotron-title">Kindful API Docs</h1>
          <p className="jumbotron-subheading">Use the Kindful API to create meaningful integrations that help nonprofits succeed.</p></center>
        </Container>
      </Jumbotron>
      <Structure>
        <div>
          <main>{children}</main>
        </div>
      </Structure>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
