import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Footer from 'components/Footer'

const Container = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  display: flex;
  flex-direction: column;
  min-height: 40vh;
  overflow: auto;
  padding: 16px;
  color: #333;
  line-height: 1.2;

  }
`

const Structure = ({ children }) => (
 <>
  <Container>
    {children}
  </Container>
  <Footer />
</>
)
Structure.propTypes = {
  children: PropTypes.node,
  header: PropTypes.node,
}
export default Structure