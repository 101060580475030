import React from "react"

import MainLayout from 'layout/MainLayout'
import SEO from "../components/seo"

const NotFoundPage = () => (
  <MainLayout>
    <SEO title="404: Not found" />
    <h1>Oops! Something went wrong...</h1>
    <p>This page is no longer available</p>
  </MainLayout>
)

export default NotFoundPage
